import { LocalizedString } from 'typesafe-i18n';

export type TranslationRenderPart = (
  messagePart: LocalizedString
) => JSX.Element;

type Props = {
  /**
   * The text to be rendered, containing <> split indicators.
   * @example "Hello, I <>love<> dogs!"
   */
  text: LocalizedString;
  /**
   * Method to wrap and render text contained in <> split indicators.
   * Where `text` contains multiple split phrases this prop can be an array of
   * render methods, one for each split phrase. If providing an array, always set
   * a unique `key` prop on the returned JSX for each method.
   * @example
   * // If `text` is
   * "Hello I <>love<> dogs!"
   * // then a `renderComponent` method of
   * (text) => <b>{text}</b>
   * // will return
   * <React.Fragment>Hello I <b>love</b> dogs!</React.Fragment>
   *
   * // If `text` contains multiple splits, for example
   * "I love <>dogs<> and <>cats<>!"
   * // then an array of `renderComponent` like
   * [
   *   (x) => <b key="first">{x}</b>,
   *   (x) => <em key="second">{x}</em>
   * ]
   * // will return
   * <React.Fragment>Hello I <b>love</b> dogs and <em>cats</em>!</React.Fragment>
   */
  renderComponent: TranslationRenderPart | TranslationRenderPart[];
};

const Translate: React.FunctionComponent<Props> = ({
  text,
  renderComponent,
}) => {
  // define a split character, in this case '<>'
  const [before, ...after] = text.split('<>') as LocalizedString[];

  // render infix only if the message doesn't have any split characters
  if (before && after.length == 0) {
    return <>{before}</>;
  }

  return (
    <>
      {before || null}
      {after.map((x, i) => {
        // Each string wrapped by <>...<> will be in an index cleanly divisible by 2
        if (i % 2 === 0) {
          // If renderComponent is an array of render methods, then the
          // matching method will be current index / 2. (i.e. if we're at
          // the first (index 0) split string, our render method is at index 0 / 2 = 0,
          // likewise if we are the 4th split string, the render method is at index 2)
          // This is because there should be half as many render methods
          // as split strings, assuming each split should have its own method.
          const renderIndex = i / 2;
          if (Array.isArray(renderComponent)) {
            return renderIndex < renderComponent.length
              ? renderComponent[renderIndex](x)
              : x; // If we've been silly and not provided enough methods, we fall back to the plain text.
          }
          return renderComponent(x);
        }
        return x; // Every split string at an index non-divisible by 2 is not wrapped in <>...<>, therefore render plain text
      })}
    </>
  );
};

export default Translate;
